import "csrf-xhr";

function program(elmApp, containerId, callback) {
  document.addEventListener("DOMContentLoaded", () => {
    const target = document.getElementById(containerId);
    const previousUrl = document.referrer
    const initialState = window.__INITIAL_STATE__;
    const appConfig = window.__APP_CONFIG__;
    const initialNotifications = window.__INITIAL_NOTIFICATIONS__;
    const flags = { ...initialState, ...appConfig, initialNotifications, previousUrl };

    delete window.__INITIAL_STATE__;
    delete window.__INITIAL_NOTIFICATIONS__;
    delete window.__APP_CONFIG__;

    const app = elmApp.init({
      node: target,
      flags,
    });

    startPagePorts(app);
    if (typeof callback === "function") callback(app);
  });
}

function startPagePorts(app) {
  if (!app.ports.pageOutbound) return;

  app.ports.pageOutbound.subscribe((cmd) => {
    switch (cmd.tag) {
      case "RequestPreventNavigation": {
        requestPreventNavigation();
        break;
      }

      case "CancelPreventNavigation": {
        cancelPreventNavigation();
        break;
      }

      case "AddNotification": {
        app.ports.pageInbound.send({
          tag: "GotNotification",
          notification: cmd.notification,
        });
      }

      case "ReplaceRoute": {
        history.replaceState({}, "", cmd.url);
      }

      default: {
      }
    }
  });
}

// HELPERS

const requestPreventNavigation = () => {
  window.addEventListener("beforeunload", onBeforeUnload);
};

const cancelPreventNavigation = () => {
  window.removeEventListener("beforeunload", onBeforeUnload);
};

const onBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = "";
};

// EXPORT PUBLIC FUNCTIONS

export default {
  program,
};
